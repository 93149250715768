import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const About = () => {
  const {t} = useTranslation();

  return (
    <div className="Body">
      <div className="Body-text-wrapper">
        <span className="Header-title">{t('about.title1')}</span>
        <p className="Header-text">
          {t('about.text1')}
        </p>
        <span className="Header-title">{t('about.title2')}</span>
        <p className="Header-text">
          {t('about.text2')}
        </p>
        <span className="Header-title">{t('about.title3')}</span>
        <p className="Header-text">
          {t('about.text3')}
        </p>
        <br />
        <br />
        <span className="Header-title">{t('about.title4')}</span>
        <div onClick={() => window.open(require(`../../assets/${i18n.language}_whitepaper.pdf`), '_blank')}>
          <img className="WhitePaper" src={require('../../assets/images/whitepaper.png')} alt="white paper" />
        </div>
      </div>
    </div>
  );
}

export default About;
