import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import {httpsCallable} from "@firebase/functions";
import {functions} from "../../firebaseConfig";
import i18n from "i18next";
import {useParams} from "react-router-dom";

const Market = () => {
  const {t} = useTranslation();
  const {language, userId} = useParams();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [])

  const createOrder1 = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: 50
        },
      }],
    });
  };

  const createOrder2 = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: 100
        },
      }],
    });
  };

  const createOrder3 = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: 200
        },
      }],
    });
  };

  const createOrder4 = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: 500
        },
      }],
    });
  };

  const createOrder5 = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: 1000
        },
      }],
    });
  };

  const createOrder6 = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: 2000
        },
      }],
    });
  };

  const createOrder7 = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: 5000
        },
      }],
    });
  };

  const handleApprove = (data: any, actions: any) => {
    const myFunction = httpsCallable(functions, 'paypalWebhook');
    return actions.order.capture().then(async (details: any) => {
      try {
        await myFunction({
          orderId: data.orderID,
          payer: details.payer,
          status: details.status,
          userId: userId,
        });

      } catch (e) {
        console.error('handleApprove:', e);
      }
    });
  };

  return(
    <div style={{backgroundColor: '#141414', display: 'flex', justifyContent: 'center', color: '#fff', fontWeight: 'bold'}}>
      <div>
        <div style={{color: '#e8bd5c', fontSize: 25, paddingBottom: 20}}>
          {t('market.title')}
        </div>
        <div className="card-button">
          <div>
            {`${t('market.pay')} 50 MXN`}
          </div>
          <div style={{color: '#e8bd5c'}}>
            {t('market.get')}
          </div>
          + 62
          <img className="Avatar" src={require('../../assets/images/token.png')} alt="avatar"/>
          + 5
          <img className="Avatar" src={require('../../assets/images/ticket.png')} alt="avatar" style={{marginLeft: 1}}/>
          <PayPalScriptProvider options={{ clientId: 'Ac0fL3pAjFLH3t4-rFR8tATKmNNZyv_TgvY8L60MNF8p8RuLXYCBf1d8jISSfEHe3teLetiTK_PCI85G', currency: 'MXN' }}>
            <PayPalButtons createOrder={createOrder1} onApprove={handleApprove} />
          </PayPalScriptProvider>
        </div>

        <div className="card-button">
          <div>
            {`${t('market.pay')} 100 MXN`}
          </div>
          <div style={{color: '#e8bd5c'}}>
            {t('market.get')}
          </div>
          + 125
          <img className="Avatar" src={require('../../assets/images/token.png')} alt="avatar"/>
          + 20
          <img className="Avatar" src={require('../../assets/images/ticket.png')} alt="avatar" style={{marginLeft: 1}}/>
          <PayPalScriptProvider options={{ clientId: 'Ac0fL3pAjFLH3t4-rFR8tATKmNNZyv_TgvY8L60MNF8p8RuLXYCBf1d8jISSfEHe3teLetiTK_PCI85G', currency: 'MXN' }}>
            <PayPalButtons createOrder={createOrder2} onApprove={handleApprove} />
          </PayPalScriptProvider>
        </div>

        <div className="card-button">
          <div>
            {`${t('market.pay')} 200 MXN`}
          </div>
          <div style={{color: '#e8bd5c'}}>
            {t('market.get')}
          </div>
          + 250
          <img className="Avatar" src={require('../../assets/images/token.png')} alt="avatar"/>
          + 50
          <img className="Avatar" src={require('../../assets/images/ticket.png')} alt="avatar" style={{marginLeft: 1}}/>
          <PayPalScriptProvider options={{ clientId: 'Ac0fL3pAjFLH3t4-rFR8tATKmNNZyv_TgvY8L60MNF8p8RuLXYCBf1d8jISSfEHe3teLetiTK_PCI85G', currency: 'MXN' }}>
            <PayPalButtons createOrder={createOrder3} onApprove={handleApprove} />
          </PayPalScriptProvider>
        </div>

        <div className="card-button">
          <div>
            {`${t('market.pay')} 500 MXN`}
          </div>
          <div style={{color: '#e8bd5c'}}>
            {t('market.get')}
          </div>
          + 625
          <img className="Avatar" src={require('../../assets/images/token.png')} alt="avatar"/>
          + 300
          <img className="Avatar" src={require('../../assets/images/ticket.png')} alt="avatar" style={{marginLeft: 1}}/>
          <PayPalScriptProvider options={{ clientId: 'Ac0fL3pAjFLH3t4-rFR8tATKmNNZyv_TgvY8L60MNF8p8RuLXYCBf1d8jISSfEHe3teLetiTK_PCI85G', currency: 'MXN' }}>
            <PayPalButtons createOrder={createOrder4} onApprove={handleApprove} />
          </PayPalScriptProvider>
        </div>

        <div className="card-button">
          <div>
            {`${t('market.pay')} 1,000 MXN`}
          </div>
          <div style={{color: '#e8bd5c'}}>
            {t('market.get')}
          </div>
          + 1,125
          <img className="Avatar" src={require('../../assets/images/token.png')} alt="avatar"/>
          + 800
          <img className="Avatar" src={require('../../assets/images/ticket.png')} alt="avatar" style={{marginLeft: 1}}/>
          <PayPalScriptProvider options={{ clientId: 'Ac0fL3pAjFLH3t4-rFR8tATKmNNZyv_TgvY8L60MNF8p8RuLXYCBf1d8jISSfEHe3teLetiTK_PCI85G', currency: 'MXN' }}>
            <PayPalButtons createOrder={createOrder5} onApprove={handleApprove} />
          </PayPalScriptProvider>
        </div>

        <div className="card-button">
          <div>
            {`${t('market.pay')} 2,000 MXN`}
          </div>
          <div style={{color: '#e8bd5c'}}>
            {t('market.get')}
          </div>
          + 2,500
          <img className="Avatar" src={require('../../assets/images/token.png')} alt="avatar"/>
          + 2,000
          <img className="Avatar" src={require('../../assets/images/ticket.png')} alt="avatar" style={{marginLeft: 1}}/>
          <PayPalScriptProvider options={{ clientId: 'Ac0fL3pAjFLH3t4-rFR8tATKmNNZyv_TgvY8L60MNF8p8RuLXYCBf1d8jISSfEHe3teLetiTK_PCI85G', currency: 'MXN' }}>
            <PayPalButtons createOrder={createOrder6} onApprove={handleApprove} />
          </PayPalScriptProvider>
        </div>

        <div className="card-button">
          <div>
            {`${t('market.pay')} 5,000 MXN`}
          </div>
          <div style={{color: '#e8bd5c'}}>
            {t('market.get')}
          </div>
          + 6,250
          <img className="Avatar" src={require('../../assets/images/token.png')} alt="avatar"/>
          + 10,000
          <img className="Avatar" src={require('../../assets/images/ticket.png')} alt="avatar" style={{marginLeft: 1}}/>
          <PayPalScriptProvider options={{ clientId: 'Ac0fL3pAjFLH3t4-rFR8tATKmNNZyv_TgvY8L60MNF8p8RuLXYCBf1d8jISSfEHe3teLetiTK_PCI85G', currency: 'MXN' }}>
            <PayPalButtons createOrder={createOrder7} onApprove={handleApprove} />
          </PayPalScriptProvider>
        </div>
      </div>
    </div>
  )
}

export default Market;
