import React, {useEffect, useState} from "react";
import Banner from "../Banner";
import {doc, getDoc, serverTimestamp, setDoc} from "firebase/firestore";
import {db} from "../../firebaseConfig";
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import SendCryptoModal from "../SendCryptoModal";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "i18next";

const Server = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const { language } = useParams();

  const [user, setUser] = useState({});
  const [showSendModal, setShowSendModal] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(language);
    const id = localStorage.getItem('wallet_id');

    const validateId = async () => {
      if (id) {
        await fetchUserData(id);
      } else {
        await fetchIpAddressAndAddUser();
      }
    }

    validateId();
  }, []);

  const fetchUserData = async (id: string) => {
    try {
      const docRef = doc(db, 'users', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUser({...docSnap.data(), id: docSnap.id})
      } else {
        console.log('No such document!');
      }
    } catch (e) {
      console.error('Error fetching user document:', e);
    }
  };

  const fetchIpAddressAndAddUser = async () => {
    try {
      const response = await axios.get(`https://ipapi.co/json/`);
      const ipAddress = response.data.ip;
      const userDoc = {
        ip_address: ipAddress,
        user_agent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        screen_resolution: {
          width: window.screen.width,
          height: window.screen.height,
        },
        timestamp: serverTimestamp(),
      };
      await addUserData(userDoc);
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  const addUserData = async (userDoc: any) => {
    try {
      const userId = uuidv4();
      const docRef = doc(db, 'users', userId);

      await setDoc(docRef, userDoc);

      localStorage.setItem('wallet_id', docRef.id);
      setUser({...userDoc, id: docRef.id});
    } catch (e) {
      console.error('Error adding user document:', e);
    }
  };

  const onSendButtonClick = () => {
    setShowSendModal(true);
  };

  const onClick = () => navigate(`/${language}`);

  return (
    <div>
      <div className="Banner">
        <Banner user={user} onClick={onSendButtonClick} language={language} />
      </div>
      <div className="container" style={{display: 'flex', justifyContent: 'center'}}>
        <div className="coming-soon-container">
          <div style={{backgroundColor: 'rgba(0,0,0,0.8)', margin: 50, padding: 20, borderRadius: 20}}>
            <p className="Header-text" style={{textAlign: 'center', color: '#e8bd5c', fontWeight: 'bold'}}>
              {t('server.text')}
            </p>
            <div style={{display: 'flex', justifyContent: 'center'}} onClick={onClick}>
              <div style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                <p className="Header-text" style={{marginTop: 10, color: '#e8bd5c', fontWeight: 'bold'}}>
                  {t('server.read_more')}
                </p>
              </div>
            </div>
          </div>
          <SendCryptoModal
            user={user}
            setUser={setUser}
            setShowModal={setShowSendModal}
            showModal={showSendModal}
          />
        </div>
      </div>
    </div>
  );
}

export default Server;
