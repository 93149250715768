import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBho9xFoCGT3GOQOCjvvMxcZ8SpK66cK00",
  authDomain: "la-belica.firebaseapp.com",
  projectId: "la-belica",
  storageBucket: "la-belica.appspot.com",
  messagingSenderId: "974254702480",
  appId: "1:974254702480:web:6b1f665a4c4eca24f33d15",
  measurementId: "G-SX2NJ7C1P9"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const functions = getFunctions(app);

auth.settings.appVerificationDisabledForTesting = true;

export { auth, analytics, db, functions };
