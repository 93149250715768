import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore"
import { useTranslation } from "react-i18next";
import { db } from "../../firebaseConfig";
import { BsFacebook, BsTwitterX } from "react-icons/bs";

const Footer = () => {
  const {t} = useTranslation();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    fetchCommentsData();
  }, [])

  const fetchCommentsData = async () => {
    const querySnapshot = await getDocs(collection(db, 'comments'));
    const docsData: any = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setComments(docsData);
  };

  return (
    <footer>
      <span className="Header-title">{t('footer.buyers')}</span>
      <div className="Flex" style={{justifyContent: 'center'}}>
        <div style={{maxWidth: '100%'}}>
          {comments.length && (
            comments.map((item: any) =>
              <div key={item.id} className="Footer-card">
                <div className="Footer-text-wrapper">
                  <div>
                    <span className="Header-text" style={{fontWeight: 'bold'}}>{item.name}</span>
                  </div>
                  <div>
                    <span className="Header-text" style={{color: '#e8bd5c'}}>{t('footer.purchase', {amount: parseInt(item.amount).toLocaleString()})}</span>
                  </div>
                  <span className="Header-text">{item.comment}</span>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div style={{padding: 100}}>
        <div style={{padding: 10}}>
          <div className="Flex" style={{justifyContent: 'center'}}>
            <BsFacebook fill="#fff" size={20} style={{margin: 10}} onClick={() => window.open('https://www.facebook.com/profile.php?id=61562424834374', '_blank')} />
            {/*<BsTelegram fill="#fff" size={20} style={{margin: 10}}
                         onClick={() => window.open('https://t.me/labelica', '_blank')}/>*/}
            <BsTwitterX fill="#fff" size={20} style={{margin: 10}} onClick={() => window.open('https://x.com/labelicamx', '_blank')} />
          </div>
        </div>
        <div>
          <span className="Header-text" style={{fontSize: 10}}>info@labelica.mx</span>
        </div>
        <span className="Header-text" style={{fontSize: 10}}>{t('footer.rights')}</span>
      </div>
    </footer>
  );
}

export default Footer;
