import React from 'react';

const VideoGrid = () => {
  const videos = [
    {
      posterUrl: require('../../assets/images/token.png'),
      videoUrls: [
        require('../../assets/videos/RPReplay_Final1720535263.mp4'),
      ],
      id: "dd2dd9f1-84d7-ed8d-20bb-0190c616f35a-video"
    },
    {
      posterUrl: require('../../assets/images/token.png'),
      videoUrls: [
        require('../../assets/videos/RPReplay_Final1720536085.mp4'),
      ],
      id: "dd2dd9f1-84d7-ed8d-20bb-0190c616f356-video"
    },
    {
      posterUrl: require('../../assets/images/token.png'),
      videoUrls: [
        require('../../assets/videos/RPReplay_Final1720536365.mp4'),
      ],
      id: "dd2dd9f1-84d7-ed8d-20bb-0190c616f35c-video"
    },
    {
      posterUrl: require('../../assets/images/token.png'),
      videoUrls: [
        require('../../assets/videos/RPReplay_Final1720536334.mp4'),
      ],
      id: "dd2dd9f1-84d7-ed8d-20bb-0190c616f35c-video"
    },
    {
      posterUrl: require('../../assets/images/token.png'),
      videoUrls: [
        require('../../assets/videos/RPReplay_Final1720535472.mp4'),
      ],
      id: "dd2dd9f1-84d7-ed8d-20bb-0190c616f358-video"
    },
  ];

  return (
    <div className="VideoGrid">
      {videos.map(video => (
        <div className="VideoGridInternal" key={video.id} id={`w-node-${video.id}`}>
          <div className="background-video-6 w-background-video w-background-video-atom">
            <video
              id={video.id}
              autoPlay
              loop
              muted
              playsInline
              style={{ backgroundImage: `url(${video.posterUrl})`, width: 300}}
              data-wf-ignore="true"
              data-object-fit="cover"
            >
              {video.videoUrls.map((url, index) => (
                <source key={index} src={url} data-wf-ignore="true" />
              ))}
            </video>
            <div className="overlay"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoGrid;
