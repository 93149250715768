import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {doc, updateDoc, serverTimestamp, setDoc} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebaseConfig";
import { useSnackbar } from "notistack";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function SendCryptoModal({user, setUser, showModal, setShowModal}: any) {
  const {t} = useTranslation();
  const { id, requested_withdrawal: requestedWithdrawal } = user;
  const { enqueueSnackbar } = useSnackbar();

  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');

  const handleClose = () => {
    setShowModal(false);
    setAddress('');
    setEmail('');
  }

  const addWithdrawalData = async () => {
    try {
      const docRef = doc(db, 'withdrawals', uuidv4());

      await setDoc(docRef, {
        user_id: id,
        address: address,
        email: email,
        timestamp: serverTimestamp(),
        has_completed: false,
      });

      await updateDoc(doc(db, 'users', id), {
        requested_withdrawal: true
      });
      enqueueSnackbar(t('send_crypto_modal.success'), {
        variant: 'success',
      });
      setUser({...user, requested_withdrawal: true})
    } catch (e) {
      enqueueSnackbar(`${t('send_crypto_modal.error')} ${e}`, {
        variant: 'error',
      });
    } finally {
      setShowModal(false);
      setAddress('');
      setEmail('');
    }
  };

  const isValidateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const isValidSolanaAddress = address && address.length > 30 && !requestedWithdrawal;

  return (
    <>
      <Modal backdrop="static" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('send_crypto_modal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {requestedWithdrawal ? (
            <div>
              {t('send_crypto_modal.processing_message')}
            </div>
          ): (
            <>
              <div>
                {t('send_crypto_modal.pre_send_message')}
              </div>
              <label style={{marginTop: 20, width: '100%'}}>
                <div style={{marginBottom: 10}}>
                  {t('send_crypto_modal.wallet')}
                </div>
                <input
                  disabled={requestedWithdrawal}
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder={t('send_crypto_modal.wallet_placeholder')}
                  style={{width: '100%'}}
                />
              </label>
              <label style={{marginTop: 20, width: '100%'}}>
                <div style={{marginBottom: 10}}>
                  {t('send_crypto_modal.email')}
                </div>
                <input
                  disabled={requestedWithdrawal}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('send_crypto_modal.email_placeholder')}
                  style={{width: '100%'}}
                />
              </label>
              <div style={{marginTop: 15}}>
                {t('send_crypto_modal.no_wallet')}
              </div>
              <div style={{marginTop: 15, fontWeight: 'bold'}}>
                {t('send_crypto_modal.download')}
              </div>
              <a
                className="Flex"
                target="_blank"
                style={{padding: 10, justifyContent: 'center'}}
                href="https://phantom.app/download">
                <img
                  src={require('../../assets/images/wallet.jpg')}
                  alt="phantom wallet"
                  style={{width: '40%', borderRadius: 10}}
                />
              </a>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('send_crypto_modal.close')}
          </Button>
          <Button
            disabled={!isValidSolanaAddress || !isValidateEmail(email)}
            variant="primary"
            onClick={addWithdrawalData}>
            {t('send_crypto_modal.send')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendCryptoModal;
