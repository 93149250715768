import React, { useState } from "react";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebaseConfig";
import { useSnackbar } from "notistack";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function CommentModal({user, showModal, setShowModal}: any) {
  const {t} = useTranslation();
  const { purchase_amount: purchaseAmount } = user;
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');

  const handleClose = () => {
    setShowModal(false);
    setName('');
    setComment('');
  }

  const addCommentData = async () => {
    try {
      const docRef = doc(db, 'comments', uuidv4());

      await setDoc(docRef, {
        name: name || t('comment_modal.unknown'),
        amount: purchaseAmount,
        comment: comment,
        timestamp: serverTimestamp(),
      });

      enqueueSnackbar(t('comment_modal.comment_success'), {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(`${t('comment_modal.error')} ${e}`, {
        variant: 'error',
      });
    } finally {
      setShowModal(false);
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('comment_modal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {t('comment_modal.text')}
          </div>
          <label style={{marginTop: 20, width: '100%'}}>
            <div style={{marginBottom: 10}}>
              {t('comment_modal.name')}
            </div>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t('comment_modal.placeholder_name')}
              style={{width: '100%'}}
            />
          </label>
          <label style={{marginTop: 20, width: '100%'}}>
            <div style={{marginBottom: 10}}>
              {t('comment_modal.comment')}
            </div>
            <textarea
              // @ts-ignore
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder={t('comment_modal.placeholder_comment')}
              style={{width: '100%'}}
            />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('comment_modal.close_btn')}
          </Button>
          <Button disabled={!comment} variant="primary" onClick={addCommentData}>
            {t('comment_modal.comment_btn')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommentModal;
