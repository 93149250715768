import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const CountDown = ({ user, onClick }: any) => {
  const {t} = useTranslation();
  const { amount = 0 } = user;

  const calculateTimeLeft = () => {
    const now: any = new Date();
    const targetDate: any = new Date('January 1, 2025 00:00:00');

    const difference: number = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const timerComponents: any[] = [];

  const labels: any = {
    days: t('count_down.days'),
    hours: t('count_down.hours'),
    minutes: t('count_down.minutes'),
    seconds: t('count_down.seconds'),
  };

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <div className="timer-component" key={interval}>
        <span className="Countdown-text">{timeLeft[interval]}</span>
        <span className="Countdown-text">{labels[interval]}</span>
      </div>
    );
  });

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <video
        autoPlay
        loop
        muted
        playsInline
        data-wf-ignore="true"
        data-object-fit="cover"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "120%",
          height: "90%",
          objectFit: "cover",
        }}
      >
        <source
          src="https://pic.pikbest.com/19/63/47/32C888piCsr9.mp4"
          type="video/mp4"
          data-wf-ignore="true"
        />
        Your browser does not support the video tag.
      </video>
      <div className="CountDown" style={{ height: '100vh', zIndex: 1, position: "relative", backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        <div className="CountDown-text">
          <div>
            <span className="Header-title">{t('count_down.launch')}</span>
          </div>
          <div>
            <span className="Header-title">{t('count_down.launch_date')}</span>
          </div>
          <div>
            <span className="subtitle">{t('count_down.left')}</span>
          </div>
          <div className="Flex" style={{ justifyContent: 'center' }}>
            <div className="Flex">
              {timerComponents.length ? timerComponents : <span>{t('count_down.time_over')}</span>}
            </div>
          </div>
          <div className="Flex" style={{ justifyContent: 'center', padding: 50 }}>
            <button disabled={amount === 0} className="Header-button" onClick={onClick} style={{ zIndex: 1 }}>
              {`${t('count_down.withdrawal')}(LB)`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountDown;
